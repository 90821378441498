<template>
  <label
    class="block w-full px-2 py-1"
    :class="[value === inputValue ? 'bg-blue-medium': '']"
  >
    <span class="flex justify-center">
      <span
        v-if="preferable"
        class="w-20 h-8 text-white text-xs flex italic items-center justify-center box-border border rounded-l-lg"
        :class="{
          'bg-grey border-gray-900': preference === 'Unavailable',
          'bg-green-medium border-gray-900': preference === 'Preferred',
        }"
      >
        {{ preference }}
      </span>
      <span
        class="h-8 text-sm flex items-center justify-center py-1 border-gray-900 box-border"
        :class="[
          {
            'w-40 rounded-lg border': !preferable,
            'w-56 rounded-r-lg border-t border-b border-r': preferable,
          },
          themeClasses]"
      >
        {{ label }}
      </span>
    </span>
    
    <input
      type="radio"
      v-bind="$attrs"
      :name="name"
      class="hidden"
      :value="value"
      @input="handleChange"
      @blur="handleBlur"
    >
  </label>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useField } from 'vee-validate'
import { EntryPillType } from '@/types/roster'
import { pillThemeMap } from '@/constants/entries'

export default defineComponent({
  inheritAttrs: false,
  
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      validator: (val: EntryPillType) => [
          EntryPillType.EARLY,
          EntryPillType.MID,
          EntryPillType.LATE,
          EntryPillType.NIGHT_ONE,
          EntryPillType.NIGHT_TWO,
          EntryPillType.ON_CALL,
          EntryPillType.ABSENCE,
          EntryPillType.STUDY_LEAVE,
          EntryPillType.SICKNESS,
          EntryPillType.OFF,
        ].includes(val),
      required: true,
    },
    preferable: {
      type: Boolean,
      default: false,
    },
    preferred: {
      type: Number,
      default: null,
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      handleBlur,
      handleChange,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        valueProp: props.value,
      },
    )

    const hasPreference = computed(() => props.preferable && props.preferred !== null)

    const preference = computed(() => {
      if (!hasPreference.value) return ''

      return props.preferred === 1 ? 'Preferred' : 'Unavailable'
    })

    return {
      handleBlur,
      handleChange,
      inputValue,
      themeClasses: pillThemeMap[props.type],
      preference,
      hasPreference,
    }
  },
})
</script>
