
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup (props, ctx) {

    const hours = ref(Math.floor(props.modelValue))
    const minutes = ref(props.modelValue - hours.value)

    watch(
      [hours, minutes],
      () => {
        ctx.emit('update:modelValue', (hours.value + minutes.value))
      },
    )

    const MINUTE_OPTIONS = [
      {
        label: '0 mins',
        value: 0,
      },
      {
        label: '15 mins',
        value: 0.25,
      },
      {
        label: '30 mins',
        value: 0.5,
      },
      {
        label: '45 mins',
        value: 0.75,
      },
    ]

    const HOUR_OPTIONS = []
    for (let h = 0; h < 24; h++) {
      HOUR_OPTIONS.push({
        label: `${h} hrs`,
        value: h,
      })
    }

    return {
      HOUR_OPTIONS,
      MINUTE_OPTIONS,
      hours,
      minutes,
    }
  },
})
