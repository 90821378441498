<template>
  <div class="w-full">
    <label
      v-if="label"
      class="text-blue-medium text-sm italic mb-1 block"
    >
      {{ label }}
    </label>

    <div class="flex bg-blue-medium rounded-lg h-10 px-1 border-2 border-blue-medium">
      <select
        class="block placeholder-gray-300 focus:outline-blue-dark w-full h-full py-1 px-2 text-white bg-blue-medium text-sm"
        v-model="hours"
        :disabled="disabled"
      >
        <option
          v-for="hour in HOUR_OPTIONS"
          :key="hour.value"
          :value="hour.value"
        >
          {{ hour.label }}
        </option>
      </select>
      <select
        class="block placeholder-gray-300 focus:outline-blue-dark w-full h-full py-1 px-2 text-white bg-blue-medium text-sm"
        v-model="minutes"
        :disabled="disabled"
      >
        <option
          v-for="min in MINUTE_OPTIONS"
          :key="min.value"
          :value="min.value"
        >
          {{ min.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup (props, ctx) {

    const hours = ref(Math.floor(props.modelValue))
    const minutes = ref(props.modelValue - hours.value)

    watch(
      [hours, minutes],
      () => {
        ctx.emit('update:modelValue', (hours.value + minutes.value))
      },
    )

    const MINUTE_OPTIONS = [
      {
        label: '0 mins',
        value: 0,
      },
      {
        label: '15 mins',
        value: 0.25,
      },
      {
        label: '30 mins',
        value: 0.5,
      },
      {
        label: '45 mins',
        value: 0.75,
      },
    ]

    const HOUR_OPTIONS = []
    for (let h = 0; h < 24; h++) {
      HOUR_OPTIONS.push({
        label: `${h} hrs`,
        value: h,
      })
    }

    return {
      HOUR_OPTIONS,
      MINUTE_OPTIONS,
      hours,
      minutes,
    }
  },
})
</script>
