<template>
  <BaseToggle
    :value="checked"
    :readonly="readonly"
    :alternate="false"
    @toggle="handleToggle"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup (props) {
     const { checked, handleChange } = useField(props.name, '', {
      type: 'checkbox',
      valueProp: props.value,
    });

    const handleToggle = (value: boolean) => {
      handleChange(value)
    }

    return {
      checked,
      handleToggle,
    }
  },
})
</script>
