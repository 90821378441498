
import { Absence, DateBasics, Draftable, Entry, EntryStatus, Shift, TypedAbsence, TypedShift } from '@/types/roster'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import moment from 'moment'
import ShiftsAPI from '@/apis/rota-architect/shifts'
import AbsencesAPI from '@/apis/rota-architect/absences'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'
import useToasts from '@/hooks/useToasts'
import { 
  shiftTextContent,
  shiftPillType,
  absencePillType,
  entryPillType,
  entryPillLabel,
} from '@/hooks/useSingleUserRotaTable'
import ROTA_ID from '@/constants/rota'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '../EntryPill.vue'
import VEntryRadioInput from './VEntryRadioInput.vue'
import VToggle from '@/components/rota/form/VToggle.vue'
import VShiftDurationInput from '@/components/rota/form/VShiftDurationInput.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'

export default defineComponent({
  components: {
    VEntryRadioInput,
    VToggle,
    EntryPill,
    VShiftDurationInput,
    EditIcon,
    CloseIcon,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    currentEntry: {
      type: Object as PropType<Entry>,
      default: null,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    // if current is deleting, it should be 'silent' and persisted.
    const currentIsDeleting = computed(() => props.currentEntry && props.currentEntry.status === EntryStatus.DELETING)

    const formHeading = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Replacing current entry' : 'Creating a draft entry')

    const isLocumQuestion = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Replacing with a locum shift?' : 'Is this a locum shift?')

    const alterationsHeading = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Altering replacement shift times:' : 'Altering shift times:')

    const createButtonText = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Replace with draft' : 'Create draft')

    const currentEntryLabel = computed(() => props.currentEntry && !currentIsDeleting.value ? entryPillLabel(props.currentEntry, 'name') : '')

    const currentEntryType = computed(() => props.currentEntry && !currentIsDeleting.value ? entryPillType(props.currentEntry) : null)

    /**
     * Fetch entry options
     */
    const loadingShiftOptions = ref(true)
    const loadingAbsenceOptions = ref(true)
    const shiftOptions = ref<TypedShift[]>([])
    const absenceOptions = ref<TypedAbsence[]>([])

    ShiftsAPI.all(`?user_id=${props.userId}&day_id=${props.date.day_id}&rota_id=${ROTA_ID}`)
      .then((res) => {
        shiftOptions.value = res.data.map((item: Shift) => ({
          ...item,
          type: 'shifts',
        }))
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingShiftOptions.value = false
      })

    AbsencesAPI.all()
      .then((res) => {
        absenceOptions.value = res.data.map((item: Absence) => ({
          ...item,
          type: 'absences',
        }))
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingAbsenceOptions.value = false
      }) 

    const loadingOptions = computed(() => loadingShiftOptions.value || loadingAbsenceOptions.value)

    /**
     * Form
     */
    const createSchema: yup.ObjectSchema<{ 
      entry: string; 
      is_locum: boolean;
      with_absence_id?: string;
      alternate_start?: string;
      alternate_hours?: number;
    }> = yup.object({
      entry: yup
        .string()
        .required('Please select an entry.'),
      is_locum: yup
        .boolean(),
      with_absence_id: yup
        .string()
        .when('is_locum', {
          is: true,
          then: yup.string(),
        }),
      alternate_start: yup
        .string(),
      alternate_hours: yup
        .number()
        .min(1, 'Length must be greater than 1 hour.'),
    }).defined()

    const { handleSubmit, values, errors, setFieldValue } = useForm({
      validationSchema:  createSchema,
      initialValues: {
        entry: '',
        is_locum: false,
        alternate_start: '00:00:00',
        alternate_hours: 0,
      },
    })

    const shiftIsSelected = computed(() => {
      if (!values.entry) return false

      return values.entry.split('_')[0] === 'shifts'
    })

    const absenceIsSelected = computed(() => {
      if (!values.entry) return false

      return values.entry.split('_')[0] === 'absences'
    })

    const selectedShiftId = computed(() => {
      if (!values.entry || !shiftIsSelected.value) return null

      return values.entry.split('_')[1]
    })

    // not input by user, so not part of form validation.
    const endTime = computed(() => {
      if (!values.alternate_start) return '00:00:00'

      return moment(values.alternate_start, 'HH:mm:ss').add(values.alternate_hours, 'hours').format('HH:mm:ss')
    })

    const submitting = ref(false)
    const submissionError = ref('')
    const withAlterations = ref(false)

    const toggleWithAlterations = () => {
      withAlterations.value = !withAlterations.value
    }

    // reset withAlterations state if an absence is selected (no alteraions on an absence)
    watch(absenceIsSelected, () => {
      if (absenceIsSelected.value) withAlterations.value = false
    })

    // if 'is_locum' is toggled to true, deselect an absence entry if selected.
    watch(values, (newValues) => {
      if (newValues.is_locum && absenceIsSelected.value) setFieldValue('entry', '')
    })

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      const rosterable = values.entry.split('_')
      const payload: Draftable = {
        rosterable_type: rosterable[0],
        rosterable_id: +rosterable[1],
        user_id: +props.userId,
        date_id: props.date.id,
        is_locum: values.is_locum,
        rota_id: ROTA_ID,
        with_absence_id: values.with_absence_id ? +values.with_absence_id : null,
        alternate_start: values.alternate_start ? values.alternate_start : null,
        alternate_end: values.alternate_start ? endTime.value : null,
        alternate_hours: values.alternate_start && values.alternate_hours ? values.alternate_hours : null,
      }

      EntriesAPI.createDraft(payload)
        .then((res) => {
          dispatch.successToast('Entry added successfully.')

          const newEntries = 
            Array.isArray(res.data) 
              ? res.data // could be multi-entry if replacing an 'approved'
              : [res.data]

          // persist existing ('deleting') entry.
          if (currentIsDeleting.value) newEntries.unshift(props.currentEntry)

          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    });

    /**
     * Deleting
     */
    const onDelete = () => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      EntriesAPI.deleteEntry(props.currentEntry.entry_id)
        .then((res) => {
          dispatch.successToast('Entry marked as \'deleting\' successfully.')
          ctx.emit('success', props.date.id, [res.data]) // only entry present
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    }

    return {
      formattedDate,
      formHeading,
      isLocumQuestion,
      createButtonText,
      currentIsDeleting,
      currentEntryLabel,
      currentEntryType,
      loadingOptions,
      shiftOptions,
      absenceOptions,
      values,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
      shiftTextContent,
      shiftPillType,
      absencePillType,
      onDelete,
      // alterations
      alterationsHeading,
      shiftIsSelected,
      selectedShiftId,
      withAlterations,
      toggleWithAlterations,
      endTime,
    }
  },
})
