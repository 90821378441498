<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="0 0 253.5 252.75"
    enable-background="new 0 0 253.5 252.75"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <path
      fill="currentColor"
      stroke="#FFFFFF"
      stroke-width="8"
      stroke-miterlimit="10"
      d="M248.414,126.524
	c0,67.382-54.602,121.99-121.993,121.99c-67.378,0-121.985-54.606-121.985-121.99c0-67.372,54.607-121.985,121.985-121.985
	C193.813,4.539,248.414,59.152,248.414,126.524z"
    />
    <polygon
      fill="#FFFFFF"
      points="179.175,47.875 67.425,147.125 97.675,180.375 210.425,82.125 "
    />
    <polygon
      fill="#FFFFFF"
      points="63.175,150.875 93.675,183.375 44.925,195.625 "
    />
  </svg>
</template>