
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

import ShiftDurationInput from '@/components/rota/form/ShiftDurationInput.vue'

export default defineComponent({
  components: {
    ShiftDurationInput,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    name: { // allows browser autocompletion
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup (props) {

    const { 
      value: inputValue, 
      errorMessage,
      handleChange,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        initialValue: props.value,
      },
    ) 

    return {
      handleChange,
      errorMessage,
      inputValue,
    }
  },
})
