
import { computed, defineComponent } from 'vue'
import { useField } from 'vee-validate'
import { EntryPillType } from '@/types/roster'
import { pillThemeMap } from '@/constants/entries'

export default defineComponent({
  inheritAttrs: false,
  
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      validator: (val: EntryPillType) => [
          EntryPillType.EARLY,
          EntryPillType.MID,
          EntryPillType.LATE,
          EntryPillType.NIGHT_ONE,
          EntryPillType.NIGHT_TWO,
          EntryPillType.ON_CALL,
          EntryPillType.ABSENCE,
          EntryPillType.STUDY_LEAVE,
          EntryPillType.SICKNESS,
          EntryPillType.OFF,
        ].includes(val),
      required: true,
    },
    preferable: {
      type: Boolean,
      default: false,
    },
    preferred: {
      type: Number,
      default: null,
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      handleBlur,
      handleChange,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        valueProp: props.value,
      },
    )

    const hasPreference = computed(() => props.preferable && props.preferred !== null)

    const preference = computed(() => {
      if (!hasPreference.value) return ''

      return props.preferred === 1 ? 'Preferred' : 'Unavailable'
    })

    return {
      handleBlur,
      handleChange,
      inputValue,
      themeClasses: pillThemeMap[props.type],
      preference,
      hasPreference,
    }
  },
})
