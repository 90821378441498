
import { DateBasics, Entry } from '@/types/roster'
import { defineComponent, PropType, ref, computed } from 'vue'
import moment from 'moment'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'
import useToasts from '@/hooks/useToasts'
import { entryPillType, entryPillLabel } from '@/hooks/useSingleUserRotaTable'

// Components
import EntryPill from '../EntryPill.vue'

export default defineComponent({
  components: {
    EntryPill,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    deletingEntry: {
      type: Object as PropType<Entry>,
      default: null,
    },
    persistEntry: {
      type: Object as PropType<Entry>,
      default: null,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    const deletingEntryLabel = computed(() => props.deletingEntry ? entryPillLabel(props.deletingEntry, 'name') : '')

    const deletingEntryType = computed(() => props.deletingEntry ? entryPillType(props.deletingEntry) : null)

    const submitting = ref(false)
    const submissionError = ref('')

    const onDelete = () => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      EntriesAPI.deleteEntry(props.deletingEntry.entry_id)
        .then(() => {
          dispatch.successToast('Entry was deleted successfully.')
          const newEntries = props.persistEntry ? [props.persistEntry] : []
          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    }

    const onRestore = () => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      EntriesAPI.approveEntry(props.deletingEntry.entry_id)
        .then((res) => {
          dispatch.successToast('Entry was restored successfully.')
          const entry = Array.isArray(res.data) ? res.data : [res.data] // could be multi-entry
          ctx.emit('success', props.date.id, entry)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    }

    return {
      formattedDate,
      deletingEntryLabel,
      deletingEntryType,
      submitting,
      submissionError,
      onDelete,
      onRestore,
    }
  },
})
