<template>
  <header class="bg-blue-medium flex items-center justify-center text-white text-xl">
    <span class="py-3 w-full text-right">
      {{ formattedDate }}
    </span>
    <div class="border-l border-white border-r py-1 h-8 mx-3" />
    <span class="py-3 w-full">
      {{ userName }}
    </span>
  </header>

  <div
    v-if="currentEntry && !currentIsDeleting"
    class="w-full text-center bg-gray-200 py-3 px-4"
  >
    <h3 class="text-blue-dark font-bold uppercase text-lg">
      Current
    </h3>
    <div class="flex justify-center w-64 mx-auto">
      <EntryPill
        :content="currentEntryLabel"
        :type="currentEntryType"
        :status="currentEntry.status"
        :is-locum="!!currentEntry.is_locum"
        :is-with-absence="currentEntry.with_absence_id"
        :with-absence="currentEntry.with_absence ?? ''"
        tooltip
        size="full"
      />
    </div>
  </div>

  <div class="bg-blue-dark flex items-center justify-center w-100 py-3">
    <p class="text-white">
      {{ isLocumQuestion }}
    </p>
    <span class="flex items-center ml-6">
      <span 
        class="mr-2"
        :class="[values.is_locum ? 'text-gray-800' : 'text-white font-bold']"
      >No</span>
      <VToggle
        name="is_locum"
        :readonly="submitting"
        :value="values.is_locum"
      />
      <span 
        class="ml-2"
        :class="[values.is_locum ? 'text-white font-bold' : 'text-gray-800']"
      >Yes</span>
    </span>
  </div>

  <form
    @submit="onSubmit"
    class="p-4"
  >
    <h2 class="text-center text-lg font-bold pt-2">
      {{ formHeading }}
    </h2>
    <p class="text-center mb-4">
      Select an entry from the available options:
    </p>
    <fieldset
      :disabled="submitting"
      class="overflow-y-hidden"
    >
      <div class="flex flex-wrap md:flex-no-wrap">
        <div class="w-fulll md:w-3/5 md:h-absences mb-6 dm:mb-0">
          <h3 class="uppercase text-center">
            Shifts
          </h3> 
        
          <template v-if="loadingOptions">
            <div
              v-for="(i) in Array(5)"
              :key="i"
              class="bg-blue-lightest pulse w-76 h-8 mb-2 mx-auto"
            />
          </template>

          <template v-else-if="shiftOptions.length">
            <span
              v-for="opt in shiftOptions"
              :key="`${opt.type}_${opt.id}`"
              class="block relative"
            >
              <VEntryRadioInput
                name="entry"
                :label="shiftTextContent(opt, 'name')"
                :value="`${opt.type}_${opt.id}`"
                :type="shiftPillType(opt.slot_id)"
                preferable
                :preferred="opt.preferred"
              />

              <span
                v-if="shiftIsSelected && +opt.id === +selectedShiftId"
                class="absolute top-1/2 transform -translate-y-1/2 right-2"
              >
                <BaseIconButton
                  :color="withAlterations ? 'text-red-medium hover:text-red-dark' : 'text-blue-medium hover:text-blue-dark'"
                  @click="toggleWithAlterations"
                >
                  <CloseIcon v-if="withAlterations" />
                  <EditIcon v-else />
                </BaseIconButton>
              </span>
            </span>
          </template>
        </div>
        <div 
          class="w-full md:w-2/5"
          :class="[
            values.is_locum ? 'opacity-25' : 'opacity-100',
            values.is_locum || withAlterations ? 'mb-4 md:mb-0' : '',
          ]"
        >
          <h3 class="uppercase text-center">
            Absences
          </h3>

          <template v-if="loadingOptions">
            <div
              v-for="(i) in Array(8)"
              :key="i"
              class="bg-blue-lightest pulse w-40 h-8 mb-2 mx-auto"
            />
          </template>

          <template v-else-if="absenceOptions.length">
            <VEntryRadioInput
              v-for="opt in absenceOptions"
              :key="`${opt.type}_${opt.id}`"
              name="entry"
              :label="opt.label"
              :value="`${opt.type}_${opt.id}`"
              :type="absencePillType(opt.id)"
              :disabled="values.is_locum"
            />
          </template>
        </div>
      </div>

      <span
        class="transition-all ease-in-out duration-500 block"
        :class="[values.is_locum ? 'max-h-entry-replacement' : 'max-h-0']"
      >
        <transition name="fade">
          <div
            v-if="values.is_locum"
            class="bg-gray-100 px-6 py-2"
          >
            <VSelectInput
              :options="[
                { value: '2', label: 'Annual Leave' },
                { value: '8', label: 'TOIL' },
              ]"
              name="with_absence_id"
              label="With Absence type"
            />
          </div>
        </transition>
      </span>
      
      <span 
        class="transition-all ease-in-out duration-500 block"
        :class="[withAlterations && shiftIsSelected ? 'max-h-entry-replacement' : 'max-h-0']"
      >
        <transition name="fade">
          <div
            v-if="withAlterations && shiftIsSelected"
            class="text-center bg-gray-100 px-6 py-2"
          >
            <h3 class="mb-1">
              {{ alterationsHeading }}
            </h3>

            <section class="flex justify-center">
              <span class="block w-1/3 mr-2">
                <VShiftDurationInput
                  label="Shift Length"
                  :value="values.alternate_hours"
                  name="alternate_hours"
                  :disabled="submitting"
                />
              </span>
              <span class="block w-1/3 mx-2">
                <VTimeInput
                  label="Start Time"
                  :value="values.alternate_start"
                  name="alternate_start"
                  :disabled="submitting"
                />
              </span>
              <span class="block w-1/3 ml-2">
                <TimeInput
                  label="End Time"
                  v-model="endTime"
                  :disabled="true"
                />
              </span>
            </section>
          </div>
        </transition>
      </span>
    </fieldset>

    <div
      v-if="formErrors.entry"
      class="text-center text-red-medium my-4"
    >
      {{ formErrors.entry }}
    </div>

    <BaseErrorMessage
      v-if="submissionError"
      extend-wrapper-classes="my-3 md:my-6"
    >
      {{ submissionError }}
    </BaseErrorMessage>

    <div class="flex flex-col md:flex-row justify-end items-center gap-3 mt-4 pt-4 border-t-2 border-gray-200">
      <span
        v-if="currentEntry && !currentIsDeleting"
        class="mr-auto"
      >
        <BaseButton
          size="md"
          theme="delete"
          @click="onDelete"
          :disabled="submitting"
        >
          Mark as deleting
        </BaseButton>
      </span>

      <BaseButton
        size="md"
        theme="base"
        @click="$emit('cancel')"
        :disabled="submitting"
      >
        Cancel
      </BaseButton>

      <!-- Submit -->
      <BaseButton
        theme="success"
        size="md"
        type="submit"
        :disabled="loadingOptions"
        :working="submitting"
      >
        {{ createButtonText }}
      </BaseButton>
    </div>
  </form>
</template>

<script lang="ts">
import { Absence, DateBasics, Draftable, Entry, EntryStatus, Shift, TypedAbsence, TypedShift } from '@/types/roster'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import moment from 'moment'
import ShiftsAPI from '@/apis/rota-architect/shifts'
import AbsencesAPI from '@/apis/rota-architect/absences'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'
import useToasts from '@/hooks/useToasts'
import { 
  shiftTextContent,
  shiftPillType,
  absencePillType,
  entryPillType,
  entryPillLabel,
} from '@/hooks/useSingleUserRotaTable'
import ROTA_ID from '@/constants/rota'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '../EntryPill.vue'
import VEntryRadioInput from './VEntryRadioInput.vue'
import VToggle from '@/components/rota/form/VToggle.vue'
import VShiftDurationInput from '@/components/rota/form/VShiftDurationInput.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'

export default defineComponent({
  components: {
    VEntryRadioInput,
    VToggle,
    EntryPill,
    VShiftDurationInput,
    EditIcon,
    CloseIcon,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    currentEntry: {
      type: Object as PropType<Entry>,
      default: null,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    // if current is deleting, it should be 'silent' and persisted.
    const currentIsDeleting = computed(() => props.currentEntry && props.currentEntry.status === EntryStatus.DELETING)

    const formHeading = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Replacing current entry' : 'Creating a draft entry')

    const isLocumQuestion = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Replacing with a locum shift?' : 'Is this a locum shift?')

    const alterationsHeading = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Altering replacement shift times:' : 'Altering shift times:')

    const createButtonText = computed(() => props.currentEntry && !currentIsDeleting.value ? 'Replace with draft' : 'Create draft')

    const currentEntryLabel = computed(() => props.currentEntry && !currentIsDeleting.value ? entryPillLabel(props.currentEntry, 'name') : '')

    const currentEntryType = computed(() => props.currentEntry && !currentIsDeleting.value ? entryPillType(props.currentEntry) : null)

    /**
     * Fetch entry options
     */
    const loadingShiftOptions = ref(true)
    const loadingAbsenceOptions = ref(true)
    const shiftOptions = ref<TypedShift[]>([])
    const absenceOptions = ref<TypedAbsence[]>([])

    ShiftsAPI.all(`?user_id=${props.userId}&day_id=${props.date.day_id}&rota_id=${ROTA_ID}`)
      .then((res) => {
        shiftOptions.value = res.data.map((item: Shift) => ({
          ...item,
          type: 'shifts',
        }))
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingShiftOptions.value = false
      })

    AbsencesAPI.all()
      .then((res) => {
        absenceOptions.value = res.data.map((item: Absence) => ({
          ...item,
          type: 'absences',
        }))
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingAbsenceOptions.value = false
      }) 

    const loadingOptions = computed(() => loadingShiftOptions.value || loadingAbsenceOptions.value)

    /**
     * Form
     */
    const createSchema: yup.ObjectSchema<{ 
      entry: string; 
      is_locum: boolean;
      with_absence_id?: string;
      alternate_start?: string;
      alternate_hours?: number;
    }> = yup.object({
      entry: yup
        .string()
        .required('Please select an entry.'),
      is_locum: yup
        .boolean(),
      with_absence_id: yup
        .string()
        .when('is_locum', {
          is: true,
          then: yup.string(),
        }),
      alternate_start: yup
        .string(),
      alternate_hours: yup
        .number()
        .min(1, 'Length must be greater than 1 hour.'),
    }).defined()

    const { handleSubmit, values, errors, setFieldValue } = useForm({
      validationSchema:  createSchema,
      initialValues: {
        entry: '',
        is_locum: false,
        alternate_start: '00:00:00',
        alternate_hours: 0,
      },
    })

    const shiftIsSelected = computed(() => {
      if (!values.entry) return false

      return values.entry.split('_')[0] === 'shifts'
    })

    const absenceIsSelected = computed(() => {
      if (!values.entry) return false

      return values.entry.split('_')[0] === 'absences'
    })

    const selectedShiftId = computed(() => {
      if (!values.entry || !shiftIsSelected.value) return null

      return values.entry.split('_')[1]
    })

    // not input by user, so not part of form validation.
    const endTime = computed(() => {
      if (!values.alternate_start) return '00:00:00'

      return moment(values.alternate_start, 'HH:mm:ss').add(values.alternate_hours, 'hours').format('HH:mm:ss')
    })

    const submitting = ref(false)
    const submissionError = ref('')
    const withAlterations = ref(false)

    const toggleWithAlterations = () => {
      withAlterations.value = !withAlterations.value
    }

    // reset withAlterations state if an absence is selected (no alteraions on an absence)
    watch(absenceIsSelected, () => {
      if (absenceIsSelected.value) withAlterations.value = false
    })

    // if 'is_locum' is toggled to true, deselect an absence entry if selected.
    watch(values, (newValues) => {
      if (newValues.is_locum && absenceIsSelected.value) setFieldValue('entry', '')
    })

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      const rosterable = values.entry.split('_')
      const payload: Draftable = {
        rosterable_type: rosterable[0],
        rosterable_id: +rosterable[1],
        user_id: +props.userId,
        date_id: props.date.id,
        is_locum: values.is_locum,
        rota_id: ROTA_ID,
        with_absence_id: values.with_absence_id ? +values.with_absence_id : null,
        alternate_start: values.alternate_start ? values.alternate_start : null,
        alternate_end: values.alternate_start ? endTime.value : null,
        alternate_hours: values.alternate_start && values.alternate_hours ? values.alternate_hours : null,
      }

      EntriesAPI.createDraft(payload)
        .then((res) => {
          dispatch.successToast('Entry added successfully.')

          const newEntries = 
            Array.isArray(res.data) 
              ? res.data // could be multi-entry if replacing an 'approved'
              : [res.data]

          // persist existing ('deleting') entry.
          if (currentIsDeleting.value) newEntries.unshift(props.currentEntry)

          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    });

    /**
     * Deleting
     */
    const onDelete = () => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      EntriesAPI.deleteEntry(props.currentEntry.entry_id)
        .then((res) => {
          dispatch.successToast('Entry marked as \'deleting\' successfully.')
          ctx.emit('success', props.date.id, [res.data]) // only entry present
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    }

    return {
      formattedDate,
      formHeading,
      isLocumQuestion,
      createButtonText,
      currentIsDeleting,
      currentEntryLabel,
      currentEntryType,
      loadingOptions,
      shiftOptions,
      absenceOptions,
      values,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
      shiftTextContent,
      shiftPillType,
      absencePillType,
      onDelete,
      // alterations
      alterationsHeading,
      shiftIsSelected,
      selectedShiftId,
      withAlterations,
      toggleWithAlterations,
      endTime,
    }
  },
})
</script>
