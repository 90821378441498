<template>
  <header class="bg-blue-medium flex items-center justify-center text-white text-xl">
    <span class="py-3 w-full text-right">
      {{ formattedDate }}
    </span>
    <div class="border-l border-white border-r py-1 h-8 mx-3" />
    <span class="py-3 w-full">
      {{ userName }}
    </span>
  </header>
  
  <section class="flex items-center bg-gray-200 py-6 px-4">
    <div
      v-if="deletingEntry"
      class="w-full text-center"
    >
      <h3 class="text-red-dark font-bold uppercase text-lg">
        Deleting*
      </h3>
      <div class="flex justify-center w-64 mx-auto">
        <EntryPill
          :content="deletingEntryLabel"
          :type="deletingEntryType"
          :status="deletingEntryType.status"
          :is-locum="!!deletingEntry.is_locum"
          :is-with-absence="!!deletingEntry.with_absence_id"
          :with-absence="deletingEntry.with_absence ?? ''"
          tooltip
          size="full"
        />
      </div>
    </div>

    <div
      v-if="approvingEntry"
      class="w-full text-center"
    >
      <h3 class="text-green-dark font-bold uppercase text-lg">
        Approving
      </h3>
      <div class="flex justify-center w-64 mx-auto">
        <EntryPill
          :content="approvingEntryLabel"
          :type="approvingEntryType"
          :status="approvingEntry.status"
          :is-locum="!!approvingEntry.is_locum"
          :is-with-absence="!!approvingEntry.with_absence_id"
          :with-absence="approvingEntry.with_absence ?? ''"
          tooltip
          size="full"
        />
      </div>
    </div>
  </section>
  
  <div class="p-6">
    <section class="mb-20 mt-4">
      <h3 class="font-bold text-lg mb-3">
        Approve Draft Entry
      </h3>
      <p class="mb-1">
        Are you sure you want to <b class="text-green-dark">approve</b> this entry?
      </p>
      <p class="mb-1">
        Once approved, this entry will be visible in the Live rota.
      </p>
      <p
        v-if="deletingEntry"
        class="text-sm italic text-blue-medium w-8/12"
      >
        *As there is also an entry marked <b class="text-red-dark">deleting</b> on this day, this entry will automatically be deleted when you approve.
      </p>
    </section>
  
    <BaseErrorMessage
      v-if="submissionError"
      extend-wrapper-classes="mb-3 md:mb-6"
    >
      {{ submissionError }}
    </BaseErrorMessage>

    <div class="flex flex-col md:flex-row justify-end items-center gap-3 mt-4 pt-4 border-t-2 border-gray-200">
      <span
        v-if="approvingEntry"
        class="mr-auto"
      >
        <BaseButton
          size="md"
          theme="delete"
          @click="onDelete"
          :disabled="submitting"
        >
          Delete instead
        </BaseButton>
      </span>

      <BaseButton
        size="md"
        theme="base"
        @click="$emit('cancel')"
        :disabled="submitting"
      >
        Cancel
      </BaseButton>

      <!-- Submit -->
      <BaseButton
        theme="success"
        size="md"
        @click="onApprove"
        :working="submitting"
      >
        Approve
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { DateBasics, Entry } from '@/types/roster'
import { defineComponent, PropType, ref, computed } from 'vue'
import moment from 'moment'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'
import useToasts from '@/hooks/useToasts'
import { entryPillType, entryPillLabel } from '@/hooks/useSingleUserRotaTable'

// Components
import EntryPill from '../EntryPill.vue'

export default defineComponent({
  components: {
    EntryPill,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    approvingEntry: {
      type: Object as PropType<Entry>,
      default: null,
    },
    deletingEntry: {
      type: Object as PropType<Entry>,
      default: null,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    const approvingEntryLabel = computed(() => props.approvingEntry ? entryPillLabel(props.approvingEntry, 'name') : '')

    const approvingEntryType = computed(() => props.approvingEntry ? entryPillType(props.approvingEntry) : null)

    const deletingEntryLabel = computed(() => props.deletingEntry ? entryPillLabel(props.deletingEntry, 'name') : '')

    const deletingEntryType = computed(() => props.deletingEntry ? entryPillType(props.deletingEntry) : null)

    const submitting = ref(false)
    const submissionError = ref('')

    /**
     * Approving
     */
    const onApprove = () => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      EntriesAPI.approveEntry(props.approvingEntry.entry_id)
        .then((res) => {
          dispatch.successToast('Entry was approved successfully.')
          const newEntries = Array.isArray(res.data) ? res.data : [res.data] // could be multi-entry
          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    }

    /**
     * Deleting
     */
    const onDelete = () => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      EntriesAPI.deleteEntry(props.approvingEntry.entry_id)
        .then(() => {
          dispatch.successToast('Entry draft was deleted successfully.')
          const newEntries = props.deletingEntry ? [props.deletingEntry] : []
          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    }

    return {
      formattedDate,
      approvingEntryLabel,
      approvingEntryType,
      deletingEntryLabel,
      deletingEntryType,
      submitting,
      submissionError,
      onApprove,
      onDelete,
    }
  },
})
</script>
